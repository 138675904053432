.footer-main {
    height: 17.8%;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.footer-image {
    width: 100%;
    height: 100%;
    max-width: 1920px;
}

.footer-image img {
    max-width: 100%;
    height: 130px;
}

@media only screen and (max-width: 1110px) {
    .footer-main {
        display: flex;
        align-items: flex-end;
    }
}