.cv-main {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-height: fit-content;
    margin: 120px;
}

.cv-card {
    display: flex;
    height: 360px;
    width: 1000px;
}

.cv-bullets {
    height: 100%;
    box-shadow: 15px 0 9px -15px #1f2235;
    width: 320px;
}

.cv-bullet-details {
    flex-grow: 1;
    width: 600px;
    overflow: hidden;
    padding: 0 0 0 70px;
}

.history-description {
    margin: 10px 0 0 0;
    text-align: justify;
    max-width: 100%;
}

.cv-details-carousel {
    transition: transform 1s ease-out;
}

.bullets-container {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;
}

.bullets {
    width: 80%;
    position: relative;
    z-index: 2;

}

.bullet {
    display: flex;
    align-items: center;
    background-color: #1f2235;
    color: #1f2235;
    height: 40px;
    margin: 15px 0;
    padding: 0 8px;
    border-radius: 20px;
    width: 30px;
    transition: width 0.6s ease;
    cursor: pointer;
}

.bullet-label {
    font-size: 14px;
    white-space: nowrap;
    font-family: "Poppins SemiBold";
}

.bullet-logo {
    height: 16px;
    margin: 0 30px 0 0;
}

.bullet-icons {
    width: 34px;
    height: 100%;
    z-index: 1;
    background-color: #1f2235;
    position: absolute;
}

.selected-bullet {
    color: white;
    width: 100%;

}

.cv-screen-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 360px;
    width: 100%;
}

.cv-heading {
    display: flex;
    flex-direction: column;
}

.cv-main-heading {
    position: relative;
    display: flex;
    justify-content: space-between;
}

.heading-bullet {
    position: absolute;
    left: -30px;
    height: 15px;
    width: 15px;
    top: 5px;
    background-color: var(--yellow);
    border-radius: 50%;
}

.cv-sub-heading {
    font-size: 14px;
    font-family: "Poppins SemiBold";
    color: #111;
    margin: 10px 0 0 0;

}

.cv-heading-description {
    font-size: 12px;
    text-align: justify;
}
.heading-date{
    background-color: var(--yellow);
    padding: 4px 12px;
    font-size: 14px;
    border-radius: 14px;
    color: white;
}
.skill-parent{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 50%;
}
.programming-skills-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.skill-percentage{
    position: relative;
    height: 15px;
    width: 70%;
    background-color: var(--yellow);
}
.skill-percentage .active-percentage {
    height: 15px;
    transition: 0.8s ease;
    position: absolute;
    background-color: #1f2235;
}
.skill-parent span{
    font-family: "Poppins SemiBold";
    font-size: 16px;
    color: #1f2235;
}
@media only screen and (max-width: 1110px){
    .cv-card {
        width: 880px;
    }
    .cv-bullet-details{
        padding: 0 0 0 70px;
    }
    .programming-skills-container {
    margin-left: 10%;
}
}
@media only screen and (max-width: 1023px){
   
.cv-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content
}
.bullets{
    width: 88%;
}
.programming-skills-container {
margin-left: 10%;
}
.cv-card{
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin: 30px 0;
    align-items: center;
    width: 88%;
}
.cv-bullet-details{
    height: 360px;
    width: fit-content;
    padding: 0;
}
.cv-bullets{
    width: 100%;
    margin: 30px 0;

}
.selected-bullet{
    width: 100%;
}
}

@media only screen and (max-width: 850px){
    .cv-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: fit-content
    }
    .bullets{
        width: 100%;
    }
    .heading-bullet {
        position: absolute;
        margin-left: 10px;
        height: 15px;
        width: 15px;
        top: 5px;
        background-color: var(--yellow);
        border-radius: 50%;
    }
    .programming-skills-container {
        margin-bottom: 10%;
    }
    .cv-card{
        height: fit-content;
        display: flex;
        flex-direction: column;
        margin: 10px 0;
        align-items: center;
        width: 100%;
    }
    .cv-bullet-details{
        height: 360px;
        width: 100%;
        padding: 0;
    }
    .heading-date{
        background-color: var(--yellow);
        margin-left: 10px;
    font-size: 14px;
    border-radius: 14px;
    color: white;
    }

    .cv-bullets{
        width: 100%;
        margin: 30px 0;
    
    }
    .selected-bullet{
        width: 100%;
    }
}