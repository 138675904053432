.header-main {
    display: flex;
    justify-content: center;
    height: 140px;
    color: var(--white);

}

.header-parent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 70%;
    color: var(--white);
}

.header-logo {
    font-size: 20px;
    font-family: "Poppins ExtraBold";
    color: var(--white);
}

.header-options {
    display: flex;
    align-items: center;
}

.header-option {
    font-size: 16px;
    cursor: pointer;
    font-weight: 600;
}

.header-hamburger {
    display: none;
    color: var(--white)
}

.header-option-seperator {
    margin: 0 50px 0 0;
    color: var(--white)
}

.selected-header-option {
    color: var(--yellow)
}

@media only screen and (max-width: 1110px) {
    .header-parent {
        width: 80%;
    }
}

@media only screen and (max-width: 970px) {
    .header-hamburger {
        display: block;
    }

    .header-parent {
        width: 100%;
        padding: 0 40px;
        position: relative;
    }

    .header-options {
        display: flex;
        flex-direction: column;
        position: absolute;
        left: -100%;
        top: 0px;
        height: 100vh;
        width: 100%;
        justify-content: space-around;
        background-color: #1f2235;
        z-index: 1000;
        opacity: 0.5;
        transition: all 0.8s ease-out;
    }

    .header-option-seperator {
        margin: 0;
    }

    .selected-header-option {
        color: var(--white)
    }

    .header-option {
        color: var(--white)
    }

    .header-hamburger-bars {
        font-size: 30px;
        cursor: pointer;
        margin: 0px 10px;
        color: var(--white)
    }

    .show-hamburger-options {
        left: 0;
        opacity: 1;
    }
}